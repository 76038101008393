@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap');

.main-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: flex-start;
    overflow: hidden;
}

.img-logo {
    height: 100px;
}

.main-star {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #F7F9FC;
    padding-bottom: 10px;
    margin-top: 10px;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    white-space: break-spaces;
}
.main-form {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: #fff;
    justify-content: flex-start;
    font-weight: 500;
    padding-top: 10px;
    border: 10px solid #F7F8FA;
    width: 450px;
    position: relative;
}
.user-form {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: flex-start;
    font-weight: 500;
    padding-top: 10px;
    /* width: 450px; */
    width: 100%;
    position: relative;
}
.watermark-img-top {
    position: absolute;
    top: -30px;
    right: -30px;
    transform: rotate(29deg);
}
.watermark-img-bottom {
    position: absolute;
    left: -30px;
    transform: rotate(215deg);
    bottom: -30px;
}
.main-select {
    width: 100%;
    height: 100%;
    background-color: #F7F9FC;
    padding-top: 10px;
    margin-bottom: 52px;
    display: flex;
    flex-direction: column;
}
.form-list {
    display: flex;
    flex-direction: column;
}
.input-checkbox {
    width: 25px;
    height: 30px;
    border: 1px solid #C6CCD5;
    background-color: #ECEEF2;
    border-radius: 20px;
}
.input-textarea {
    padding: 10px;
    height: 130px;
    background-color: #fff;
    border: 1px solid #EDF3F8;
    border-radius: 14px;
    font-size: 12px;
    padding-top: 10px;
    margin: 10px;
}
.input-form {
    max-width: 100%;
    height: 35px;
    padding: 10px;
    border: 1px solid #D3DAE6;
    border-radius: 6px;
    font-size: 14px;
}
.input-form-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #C6CCD5;
    background-color: #ECEEF2;
    border-radius: 4px;
}
.star-img {
    width: 45px;
}
.font-question {
    font-size: 13px;
    color: #000;
    font-weight: bold;
    padding: 10px 10px 0px 10px;
    display: block;
    flex-direction: row;
}
.font-form-question {
    font-size: 13px;
    color: #000;
    font-weight: bold;
    padding: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
}
.font-exp {
    display: flex;
    flex-direction: column;
    flex: 25%;
    color: #788494;
    padding-right: 10px;
    text-align: center;
}
.font-heading {
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
}
.font-selectarea {
    font-size: 16px;
    color: #3B444D;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
}
.button {
    width: 100%;
    height: 55px;
    background-color: #000000;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 11px;
    padding-left: 11px;
    border: none;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
}
.textarea-main {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 6px;
    background-color: #f7f9fc;
    margin: 10px;
}
.emoji-items {
    background-color: rgb(238, 246, 242);
    display: flex;
    border: medium;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
}
.emoji-img-items {
    width: 30px;
}
.contact-div {
    width: 100%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.emoji-button {
    border: none;
    background-color: #F7F9FC;
    width: 30%;
}
.feedback {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
.feedback-font {
    font-size: 15px;
    color: rgb(120, 132, 148);
    font-weight: bold;
    padding-bottom: 20px;
    text-align: center;
    line-height: 1px;
}
.button-nps {
    display: flex;
    width: 100%;
    border-radius: 4px;
    border: none;
    height: 40px;
    color: #fff;
    justify-content: center;
    align-items: center;

}
.img-nps {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    width: 50%;
}
.submitted {
    text-align: center;
    color: #000;
}
.submit-feedback {
    text-align: center;
    color: green;
}
.subque-div {
    padding: 0px 25px 0px 25px;
}
.nps-button-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 10px;
}
.nps-buttons {
    display: flex;
    flex: 10%;
    flex-direction: row;
}
.font-exp-items {
    font-size: 11px;
    color: #788494;
    text-align: center;
}
.arrow-down {
    position: absolute;
    top: 271px;
}
.box-shadow {
    padding: 20px;
}
.logo-div {
    width: 200px;
}
.savefeedback-img {
    max-width: 100%;
}

.invalid-img {
    padding: 20px 100px 20px 100px;
}
.font-inactive{
    font-size: 13px;
}
.font-active{
    font-size: 15px;
}
.img-responsive{
    width: 30px;
}

.detailed-button {
    background-color: #fff;
    border-bottom-width: 1px;
    border-top: none;
    border-left: none;
    border-right: none;
    font-weight: bold;
}

@media only screen and (max-width: 1000px) {

    .logo-div {
        width: 350px;
    }
    .main-star {
        padding-top: 10px;
        padding-bottom: 30px;
    }
    .button {
        font-size: 25px;
        height: 70px;
    }
    .input-textarea {
        height: 150px;
    }
    .submitted {
        font-size: 25px;
    }
    .submit-feedback {
        font-size: 22px;
    }
    .font-question {
        font-size: 28px;
    }
    .font-heading {
        font-size: 30px;
    }
    .subque-div {
        padding: 0px 8px 0px 8px;
    }
    .img-nps {
        font-size: 13px;
    }
    .button-nps {
        width: 100%;
        height: 70px;
        font-size: 25px;
    }
    .arrow-down {
        top: 271px;
    }
    .star-img {
        width: 60px;
    }
    .font-inactive {
        font-size: 23px;
    }
    .font-active{
        font-size: 24px;
    }
    .emoji-img-items {
        width: 60px;
    }
    .font-exp-items {
        font-size: 23px;
    }
    .feedback-font {
        font-size: 20px;
    }
    .feedback {
        font-size: 35px;
    }
    .box-shadow {
        padding: 27px;
    }
    .savefeedback-img {
        max-width: 100%;
    }
    /* .invalid-img {
        padding: 20px 0px 20px 0px;
    } */
    .main-form{
        width: 100% !important ;
    }

    .font-selectarea {
        font-size: 23px;
    }

    .input-checkbox {
        width: 30px;
        height: 35px;
    }
    .logo{
        width: 100% !important ;
    }
    .submit-text{
        font-size: 30px !important;
    }
    .img-responsive{
        width: 40px;
    }
}
@media only screen and (max-width: 600px) {
    .logo-div {
        width: 200px;
    }
    .feedback {
        font-size: 20px;
    }
    .feedback-font {
        font-size: 15px;
    }
    .font-question {
        font-size: 15px;
    }
    .font-selectarea {
        font-size: 15px;
    }
    .button {
        font-size: 20px;
        height: 55px;
    }
    .input-form {
        font-size: 15px;
    }
    .input-textarea {
        font-size: 15px;
        margin: 10px;
    }

    .font-inactive{
        font-size: 12px;
    }
    .font-active{
        font-size: 14px;
    }
    .submitted {
        font-size: 13px;
    }
    .submit-feedback {
        font-size: 10px;
    }
    .subque-div {
        padding: 0px 4px 0px 4px;
    }
    .img-nps {
        font-size: 10px;
    }
    .font-exp-items {
        font-size: 10px;
    }
    .button-nps {
        width: 100%;
        height: 40px;
        font-size: 12px;
    }
    .arrow-down {
        top: 249px;
    }
    .star-img {
        width: 40px;
    }
    .emoji-img-items {
        width: 30px;
    }
    .box-shadow {
        padding: 7px;
    }
    .savefeedback-img {
        max-width: 100%;
    }
    .main-form{
        max-width: 100%;
    }
    .logo{
        width: 300px !important ;
    }
    .submit-text{
        font-size: 20px !important;
    }
    .img-responsive{
        width: 28px !important;
    }

}
@media only screen and (max-width: 390px) {
    .button {
        font-size: 15px;
        height: 50px;
    }
    .logo-div {
        width: 150px;
    }
    .feedback {
        font-size: 12px;
    }
    .feedback-font {
        font-size: 15px;
    }
    .font-question {
        font-size: 15px;
    }
    .font-selectarea {
        font-size: 13px;
    }
    .input-form {
        font-size: 12px;
    }
    .input-textarea {
        font-size: 12px;
        margin: 10px;
    }
    .font-inactive {
        font-size: 10px;
    }
    .font-active{
        font-size: 11px;
    }
    .submitted {
        font-size: 10px;
    }
    .submit-feedback {
        font-size: 8px;
    }
    .subque-div {
        padding: 0px 1px 0px 1px;
    }
    .img-nps {
        font-size: 8px;
    }
    .font-exp-items {
        font-size: 10px;
    }
    .button-nps {
        width: 100%;
    }
    .star-img {
        width: 30px;
    }
    .box-shadow {
        padding: 10px;
    }
    .savefeedback-img {
        max-width: 100%;
    }
    .invalid-img {
        padding: 20px 0px 20px 0px;
    }
    .main-form{
        max-width: 100%;
    }
    .logo{
        width: 250px !important ;
    }
}