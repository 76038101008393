@import "https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap";
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}

.App-header {
  color: #fff;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-app {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  display: flex;
  overflow: hidden;
}

.img-logo {
  height: 100px;
}

.main-star {
  white-space: break-spaces;
  background-color: #f7f9fc;
  border-radius: 6px;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
  display: flex;
}

.main-form {
  background-color: #fff;
  border: 10px solid #f7f8fa;
  flex-direction: column;
  justify-content: flex-start;
  width: 450px;
  padding-top: 10px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.user-form {
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.watermark-img-top {
  position: absolute;
  top: -30px;
  right: -30px;
  transform: rotate(29deg);
}

.watermark-img-bottom {
  position: absolute;
  bottom: -30px;
  left: -30px;
  transform: rotate(215deg);
}

.main-select {
  background-color: #f7f9fc;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 52px;
  padding-top: 10px;
  display: flex;
}

.form-list {
  flex-direction: column;
  display: flex;
}

.input-checkbox {
  background-color: #eceef2;
  border: 1px solid #c6ccd5;
  border-radius: 20px;
  width: 25px;
  height: 30px;
}

.input-textarea {
  background-color: #fff;
  border: 1px solid #edf3f8;
  border-radius: 14px;
  height: 130px;
  margin: 10px;
  padding: 10px;
  font-size: 12px;
}

.input-form {
  border: 1px solid #d3dae6;
  border-radius: 6px;
  max-width: 100%;
  height: 35px;
  padding: 10px;
  font-size: 14px;
}

.input-form-checkbox {
  background-color: #eceef2;
  border: 1px solid #c6ccd5;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

.star-img {
  width: 45px;
}

.font-question {
  color: #000;
  flex-direction: row;
  padding: 10px 10px 0;
  font-size: 13px;
  font-weight: bold;
  display: block;
}

.font-form-question {
  color: #000;
  flex-direction: column;
  padding: 10px 10px 0;
  font-size: 13px;
  font-weight: bold;
  display: flex;
}

.font-exp {
  color: #788494;
  text-align: center;
  flex-direction: column;
  flex: 25%;
  padding-right: 10px;
  display: flex;
}

.font-heading {
  color: #000;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: bold;
}

.font-selectarea {
  color: #3b444d;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  font-size: 16px;
  display: flex;
}

.button {
  text-align: left;
  background-color: #000;
  border: none;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  margin: 20px 50px;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.textarea-main {
  background-color: #f7f9fc;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  padding-bottom: 10px;
  display: flex;
}

.emoji-items {
  background-color: #eef6f2;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.emoji-img-items {
  width: 30px;
}

.contact-div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  display: flex;
}

.emoji-button {
  background-color: #f7f9fc;
  border: none;
  width: 30%;
}

.feedback {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.feedback-font {
  color: #788494;
  text-align: center;
  padding-bottom: 20px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1px;
}

.button-nps {
  color: #fff;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
}

.img-nps {
  flex-direction: row;
  align-items: center;
  width: 50%;
  font-size: 10px;
  display: flex;
}

.submitted {
  text-align: center;
  color: #000;
}

.submit-feedback {
  text-align: center;
  color: green;
}

.subque-div {
  padding: 0 25px;
}

.nps-button-div {
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 10px;
  display: flex;
}

.nps-buttons {
  flex-direction: row;
  flex: 10%;
  display: flex;
}

.font-exp-items {
  color: #788494;
  text-align: center;
  font-size: 11px;
}

.arrow-down {
  position: absolute;
  top: 271px;
}

.box-shadow {
  padding: 20px;
}

.logo-div {
  width: 200px;
}

.savefeedback-img {
  max-width: 100%;
}

.invalid-img {
  padding: 20px 100px;
}

.font-inactive {
  font-size: 13px;
}

.font-active {
  font-size: 15px;
}

.img-responsive {
  width: 30px;
}

.detailed-button {
  background-color: #fff;
  border-width: medium medium 1px;
  border-top-style: none;
  border-top-color: currentColor;
  border-left-style: none;
  border-left-color: currentColor;
  border-right-style: none;
  border-right-color: currentColor;
  font-weight: bold;
}

@media only screen and (max-width: 1000px) {
  .logo-div {
    width: 350px;
  }

  .main-star {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .button {
    height: 70px;
    font-size: 25px;
  }

  .input-textarea {
    height: 150px;
  }

  .submitted {
    font-size: 25px;
  }

  .submit-feedback {
    font-size: 22px;
  }

  .font-question {
    font-size: 28px;
  }

  .font-heading {
    font-size: 30px;
  }

  .subque-div {
    padding: 0 8px;
  }

  .img-nps {
    font-size: 13px;
  }

  .button-nps {
    width: 100%;
    height: 70px;
    font-size: 25px;
  }

  .arrow-down {
    top: 271px;
  }

  .star-img {
    width: 60px;
  }

  .font-inactive {
    font-size: 23px;
  }

  .font-active {
    font-size: 24px;
  }

  .emoji-img-items {
    width: 60px;
  }

  .font-exp-items {
    font-size: 23px;
  }

  .feedback-font {
    font-size: 20px;
  }

  .feedback {
    font-size: 35px;
  }

  .box-shadow {
    padding: 27px;
  }

  .savefeedback-img {
    max-width: 100%;
  }

  .main-form {
    width: 100% !important;
  }

  .font-selectarea {
    font-size: 23px;
  }

  .input-checkbox {
    width: 30px;
    height: 35px;
  }

  .logo {
    width: 100% !important;
  }

  .submit-text {
    font-size: 30px !important;
  }

  .img-responsive {
    width: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .logo-div {
    width: 200px;
  }

  .feedback {
    font-size: 20px;
  }

  .feedback-font, .font-question, .font-selectarea {
    font-size: 15px;
  }

  .button {
    height: 55px;
    font-size: 20px;
  }

  .input-form {
    font-size: 15px;
  }

  .input-textarea {
    margin: 10px;
    font-size: 15px;
  }

  .font-inactive {
    font-size: 12px;
  }

  .font-active {
    font-size: 14px;
  }

  .submitted {
    font-size: 13px;
  }

  .submit-feedback {
    font-size: 10px;
  }

  .subque-div {
    padding: 0 4px;
  }

  .img-nps, .font-exp-items {
    font-size: 10px;
  }

  .button-nps {
    width: 100%;
    height: 40px;
    font-size: 12px;
  }

  .arrow-down {
    top: 249px;
  }

  .star-img {
    width: 40px;
  }

  .emoji-img-items {
    width: 30px;
  }

  .box-shadow {
    padding: 7px;
  }

  .savefeedback-img, .main-form {
    max-width: 100%;
  }

  .logo {
    width: 300px !important;
  }

  .submit-text {
    font-size: 20px !important;
  }

  .img-responsive {
    width: 28px !important;
  }
}

@media only screen and (max-width: 390px) {
  .button {
    height: 50px;
    font-size: 15px;
  }

  .logo-div {
    width: 150px;
  }

  .feedback {
    font-size: 12px;
  }

  .feedback-font, .font-question {
    font-size: 15px;
  }

  .font-selectarea {
    font-size: 13px;
  }

  .input-form {
    font-size: 12px;
  }

  .input-textarea {
    margin: 10px;
    font-size: 12px;
  }

  .font-inactive {
    font-size: 10px;
  }

  .font-active {
    font-size: 11px;
  }

  .submitted {
    font-size: 10px;
  }

  .submit-feedback {
    font-size: 8px;
  }

  .subque-div {
    padding: 0 1px;
  }

  .img-nps {
    font-size: 8px;
  }

  .font-exp-items {
    font-size: 10px;
  }

  .button-nps {
    width: 100%;
  }

  .star-img {
    width: 30px;
  }

  .box-shadow {
    padding: 10px;
  }

  .savefeedback-img {
    max-width: 100%;
  }

  .invalid-img {
    padding: 20px 0;
  }

  .main-form {
    max-width: 100%;
  }

  .logo {
    width: 250px !important;
  }
}

/*# sourceMappingURL=index.0d66bea4.css.map */
